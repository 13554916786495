

















import Component from 'vue-class-component'
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import Attachments from '../../modules/templates/components/Attachments.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniLinkButton from '@/components/buttons/SygniLinkButton.vue';
import SygniDroparea from '@/components/inputs/SygniDroparea.vue';
import SygniTextArea from '@/components/inputs/SygniTextArea.vue';
import SygniSelect from '@/components/inputs/SygniSelect.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import { Campaign } from '../../modules/campaigns/store/types';
import { Prop } from 'vue-property-decorator';
import AddNewCampaignBoilerplate from './AddNewCampaignBoilerplate.vue';

@Component({
  components: { SygniContainerTitle, SygniDroparea, SygniInput, SygniTextArea, SygniSelect, SygniRectButton, SygniLinkButton, Attachments },
})
export default class AddNewCampaignFormStepThree extends AddNewCampaignBoilerplate {
  @Prop({ default: null }) validation: any;
  campaignData: Campaign = null;
  attachments: any[];
  
  get hasId() {
    return this.$route?.params?.id ? true : false;
  }

  refreshAttachments(attachments: any) {
    this.campaignData.attachments = attachments.map((el: any) => {
      return { title: el.name, fileId: el.referenceId, id: el?.id ? el.id : null };
    })
    this.setCampaignData();
  }

  beforeMount() {
    this.loadCampaignData();

    if(this.campaignData.attachments?.length) {
      this.campaignData.attachments = this.campaignData.attachments.map((el: any) => {
        const attachment: any = {
          title: el.title,
          fileId: el.fileId,
        };

        if(el?.id) {
          attachment.id = el.id;
        }

        return attachment;
      });
      this.setCampaignData();
      this.attachments = this.campaignData.attachments.map((el: any) => {
        return {
          name: el.title,
          referenceId: el.fileId,
          id: el.id,
          type: 'FILE'
        }
      });
    }
  }
}
